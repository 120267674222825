import React from "react";
import { Link } from "react-router-dom";
import "../css/app.css";
import EarthComp from "../components/earth";

const Home = (props) => {
  return (
    <div className="App">
      <EarthComp />

      <header className="App-header">
        <p className="Header-text">
          Achieve your business goals with{" "}
            <Link to="contact">Norehill Solutions</Link>
          {" "}your partner for global software development and artistically crafted graphic <a href="https://www.norehill.art/">Art and Design</a> works.
        </p>
        <p className="Header-text">
          You always get the perfect solution thanks to our in-depth technical
          knowledge and extensive industry experience.
        </p>
      </header>
      <div className="center">
        {/* <img className="Patrik-img" src="patrik.jpg" alt="patrik" /> */}
        <img className="Loggo-img" src="images/logo-enso-4-w-tans-bg.png" alt="Norehill Solutions" />
      </div>

      <footer className="App-footer">
      <p className="text-left">
      <a
            className="App-link"
            href="https://se.linkedin.com/in/patrikcarlander"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="Linkedin-img"
              src="images/linkedin-logo-icon.svg"
              alt="LinkedIn"
            />
            Patrik Bergström
          </a>
        </p>
        <p>
          <i>
            I have been evolving my unique competencies over many years to
            become a leading solutions architect, software developer and a trustworthy consultant who
            knows how to convert investment in technology into true business
            results.
          </i>
        </p>
        <p className="text-left">
      <a
            className="App-link"
            href="https://www.norehill.art/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="Linkedin-img"
              src="images/linkedin-logo-icon.svg"
              alt="LinkedIn"
            />
            Phuong Lê
          </a>
        </p>
        <p>
          <i>
          I'm art director and skilled drawing artist, blends imaginative leadership with masterful artistry, transforming ideas into visually stunning art and design works.
          </i>
        </p>
        <br />
        <p className="text-right">
          <Link to="contact">
            Contact...
          </Link>
        </p>
      </footer>
    </div>
  );
};

export default Home;
